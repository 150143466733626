import React, { Component } from "react";
import TrainingenPopup from "./TrainingenPopup";

class Trainingen extends Component {
  constructor() {
    super();
    this.state = {
      trainingPopupIsActive: false,
      trainingPopupData: null,
    };
  }

  render() {
    const { trainingPopupIsActive, trainingPopupData } = this.state;
    const openPopup = (item) => {
      this.setState({
        trainingPopupIsActive: true,
        trainingPopupData: item,
      });

      // const main = document.getElementById('main');
      // main.classList = 'overflow-hidden';
    };

    const closePopup = () => {
      this.setState({
        trainingPopupIsActive: false,
        trainingPopupData: null,
      });

      const main = document.getElementById("main");
      main.classList = "";
    };

    const closeAndScroll = () => {
      this.setState({
        trainingPopupIsActive: false,
        trainingPopupData: null,
      });

      const main = document.getElementById("main");
      main.classList = "";

      const contactSection = document.getElementById("contact");
      contactSection.scrollIntoView();
    };

    const trainingItems = [
      {
        title: "Masterclass en diner",
        name: "masterclass",
        price: "125",
        pricePerPersonDiner: "30",
        duration: "+- 4 uur",
        durationDiner: "Tot hoelang het gezellig is",
        maxPersons: "Minimaal aantal personen 2, maximaal aantal personen 6",
        trainingSlogan:
          "Met de masterclass leer ik je alles van kop tot staart. Achteraf mag je alles presenteren voor vrienden, familie en/of collega's.",
        trainingParagraphs: [
          "We gaan eerst samen alle ingrediënten bekijken en ik vertel uitgebreid wat we ermee gaan doen, onder het genot van een aperitief.",
          "Snijden, bakken, koken, raspen, wassen, marineren en kletsen. We maken alles klaar voordat we de gerechten kunnen afmaken.",
          "We gaan de diepte in over wat er belangrijk is voor goede sushi, de rijst en rijst azijn, het maken ervan. Verschillende Japanse sauzen maken. Het gebruik van dashi, miso en umeboshi en andere Japanse delicatessen.",
          "Na deze cursus weet je alles wat ik van mijn vader heb geleerd.",
          "We dekken de tafel, leggen mooie servetten neer en ohashi (eetstokjes) en natuurlijk jullie zelf gemaakte menu.",
          "Aan het einde van de middag komen de andere gasten binnen, jullie kunnen er bij gaan zitten en lekker relaxen maar natuurlijk mogen jullie als echte chefs ook alle gerechtjes netjes afmaken en uitserveren.",
          "Één ding is zeker: ik doe de afwas.",
        ],
        menu: [
          "Amuse",
          "Vier Japanse bijgerechten (vegetarisch)",
          "Verschillende soorten maki en nigiri (vegetarisch)",
          "Dessert",
          "Je krijgt een sushimat en een set ohashi kado t.w.v. 10 euro.",
        ],
        image: "masterclass.JPG",
        thumbnail: "masterclass_thumb.JPG",
      },
      {
        title: "Workshop",
        name: "workshop",
        price: "45",
        duration: "+- 2 uur",
        trainingSlogan:
          "Leuk om te geven, leuk om te krijgen, leuk om te doen.",
        trainingParagraphs: [
          "Een fijne middag/avond waar je leert om sushi te rollen en alles wat je rolt gelijk lekker op eten!",
          "Ik leg uit wat sushi is, hoe het is ontstaan en we gaan in op de techniek van het vormen. Zowel de Maki rollen als de Nigiri sushi.",
          "Alles staat al klaar dus het schort kan afblijven.",
        ],
        menu: [
          "Verschillende soorten maki en nigiri, geheel vegetarisch.",
          "Je krijgt een sushimat en een set ohashi kado t.w.v. 10 euro.",
        ],
        image: "workshop.JPG",
        thumbnail: "workshop_thumb.JPG",
      },
      {
        title: "Carte Blanche",
        name: "carte-blanche",
        trainingSlogan:
          "Wil je graag iets anders leren, iets toevoegen of juist eraf? Zijn er meer deelnemers die tegelijk willen? Er is zeker een vorm te vinden die bij jou past!",
        thumbnail: "carte-blanche_thumb.JPG",
      },
    ];

    return (
      <div id="trainingen" className="trainingen">
        <div className="container">
          <h1 className="section-title yellow">Trainingen</h1>
          <div className="trainingen__content">
            <div className="trainingen__content--grid">
              {trainingItems &&
                trainingItems.map((item, i) => {
                  return (
                    <div key={i} className="training-items__item">
                      <img
                        src={require(`../../assets/img/${item.thumbnail}`)}
                        alt={item.title}
                        className="training-items__item--image"
                        onClick={() => {
                          if (item.name !== "carte-blanche") {
                            openPopup(item);
                          } else {
                            const contactSection =
                              document.getElementById("contact");
                            contactSection.scrollIntoView();
                          }
                        }}
                      />
                      <div className="training-items__item--text">
                        <p className="training-items__item--title">
                          {item.title}
                        </p>
                        <p className="training-items__item--intro">
                          {item.trainingSlogan}
                        </p>
                        {item.price && (
                          <p className="training-items__item--additional-info">
                            <span>€{item.price} per persoon</span>
                            {item.name === "masterclass" ? (
                              <>
                                <span>Duur masterclass: {item.duration}</span>
                                <span>
                                  Duur diner: Tot hoelang het gezellig is.
                                </span>
                              </>
                            ) : (
                              <span>Duur: {item.duration}</span>
                            )}
                          </p>
                        )}
                        {item.name === "carte-blanche" ? (
                          <a href="#contact" className="btn btn-black">
                            Contact
                          </a>
                        ) : (
                          <button
                            className="btn btn-black"
                            onClick={() => {
                              openPopup(item);
                            }}
                          >
                            Lees verder
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
            {trainingPopupIsActive && (
              <TrainingenPopup
                closePopup={closePopup}
                closeAndScroll={closeAndScroll}
                popupData={trainingPopupData}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Trainingen;
